@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-4xl;
    @apply font-bold;
    margin-top: 4rem;
    margin-bottom: 2rem;
  }
  h2 {
    @apply text-3xl;
    @apply font-bold;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  h3 {
    @apply text-2xl;
    @apply font-bold;
  }
  h4 {
    @apply text-xl;
    @apply font-semibold;
  }
  h5 {
    @apply text-lg;
    @apply font-semibold;
  }
  h6 {
    @apply text-sm;
    @apply font-semibold;
  }
  ul, ol {
    list-style: revert;
    line-height: 1.6;
  }
  hr {
    margin: 0;
  }
}

.title_line_height {
  line-height: 1.3 !important;
}

textarea:focus, input:focus {
  outline: none !important;
}

.other a {
  color: #777;
  text-decoration: underline;
  font-size: 14px;
}

.other ul {
  padding: 0;
  margin: 0;
}

.editor-container {
  position: relative;
  line-height: 20px;
}

.editor-inner {
  font-size: 1.1rem;
  line-height: 2.1;
}

.editor-input {
  caret-color: rgb(5, 5, 5);
  position: relative;
  tab-size: 1;
  outline: 0;
  caret-color: #444;
  max-width: 610px;
}

.editor-placeholder {
  color: #999;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  top: 0;
  user-select: none;
  display: inline-block;
  pointer-events: none;
}


.editor-text-underlineStrikethrough {
  text-decoration: underline line-through;
}

.editor-text-code {
  background-color: rgb(240, 242, 245);
  padding: 1px 0.25rem;
  font-family: Menlo, Consolas, Monaco, monospace;
  font-size: 94%;
}

.editor-link {
  text-decoration: underline;
}

.tree-view-output {
  display: block;
  background: #222;
  color: #fff;
  padding: 5px;
  font-size: 12px;
  white-space: pre-wrap;
  margin: 1px auto 10px auto;
  max-height: 250px;
  position: relative;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: auto;
  line-height: 14px;
}

.editor-code {
  background-color: rgb(240, 242, 245);
  font-family: Menlo, Consolas, Monaco, monospace;
  display: block;
  padding: 8px 8px 8px 52px;
  line-height: 1.53;
  font-size: 13px;
  margin: 0;
  margin-top: 8px;
  margin-bottom: 8px;
  tab-size: 2;
  /* white-space: pre; */
  overflow-x: auto;
  position: relative;
}

.editor-code:before {
  content: attr(data-gutter);
  position: absolute;
  background-color: #eee;
  left: 0;
  top: 0;
  border-right: 1px solid #ccc;
  padding: 8px;
  color: #777;
  white-space: pre-wrap;
  text-align: right;
  min-width: 25px;
}
.editor-code:after {
  content: attr(data-highlight-language);
  top: 0;
  right: 3px;
  padding: 3px;
  font-size: 10px;
  text-transform: uppercase;
  position: absolute;
  color: rgba(0, 0, 0, 0.5);
}

.editor-tokenComment {
  color: slategray;
}

.editor-tokenPunctuation {
  color: #999;
}

.editor-tokenProperty {
  color: #905;
}

.editor-tokenSelector {
  color: #690;
}

.editor-tokenOperator {
  color: #9a6e3a;
}

.editor-tokenAttr {
  color: #07a;
}

.editor-tokenVariable {
  color: #e90;
}

.editor-tokenFunction {
  color: #dd4a68;
}

.editor-paragraph {
  margin: 0;
  margin-bottom: 8px;
  position: relative;
}

.editor-paragraph:last-child {
  margin-bottom: 0;
}

.editor-quote {
  margin-top: 20px;
  margin-left: 20px;
  color: rgb(101, 101, 101);
  border-left-color: rgb(200, 200, 200);
  border-left-width: 4px;
  border-left-style: solid;
  padding-left: 16px;
}

.editor-list-ol {
  padding: 0;
  margin: 0;
  margin-left: 14px;
}

.editor-list-ul {
  padding: 0;
  margin: 0;
  margin-left: 14px;
}

.editor-listitem {
  margin: 8px 8px 8px 16px;
}

.editor-nested-listitem {
}

pre::-webkit-scrollbar {
  background: transparent;
  width: 10px;
}

pre::-webkit-scrollbar-thumb {
  background: #999;
}

.debug-timetravel-panel {
  overflow: hidden;
  padding: 0 0 10px 0;
  margin: auto;
  display: flex;
}

.debug-timetravel-panel-slider {
  padding: 0;
  flex: 8;
}

.debug-timetravel-panel-button {
  padding: 0;
  border: 0;
  background: none;
  flex: 1;
  color: #fff;
  font-size: 12px;
}

.debug-timetravel-panel-button:hover {
  text-decoration: underline;
}

.debug-timetravel-button {
  border: 0;
  padding: 0;
  font-size: 12px;
  top: 10px;
  right: 15px;
  position: absolute;
  background: none;
  color: #fff;
}

.debug-timetravel-button:hover {
  text-decoration: underline;
}

.emoji {
  color: transparent;
  background-size: 16px 16px;
  background-position: center;
  background-repeat: no-repeat;
  vertical-align: middle;
  margin: 0 -1px;
}

.emoji-inner {
  padding: 0 0.15em;
}

.emoji-inner::selection {
  color: transparent;
  background-color: rgba(150, 150, 150, 0.4);
}

.emoji-inner::moz-selection {
  color: transparent;
  background-color: rgba(150, 150, 150, 0.4);
}

.emoji.happysmile {
  background-image: url(/images/emoji/1F642.png);
}

.toolbar {
  display: flex;
  margin-bottom: 1px;
  padding: 4px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  vertical-align: middle;
}

.toolbar button.toolbar-item {
  border: 0;
  display: flex;
  background: none;
  border-radius: 10px;
  padding: 8px;
  cursor: pointer;
  vertical-align: middle;
}

.toolbar button.toolbar-item:disabled {
  cursor: not-allowed;
}

.toolbar button.toolbar-item.spaced {
  margin-right: 2px;
}

.toolbar button.toolbar-item i.format {
  background-size: contain;
  display: inline-block;
  height: 18px;
  width: 18px;
  margin-top: 2px;
  vertical-align: -0.25em;
  display: flex;
  opacity: 0.6;
}

.toolbar button.toolbar-item:disabled i.format {
  opacity: 0.2;
}

.toolbar button.toolbar-item.active {
  background-color: rgba(223, 232, 250, 0.3);
}

.toolbar button.toolbar-item.active i {
  opacity: 1;
}

.toolbar .toolbar-item:hover:not([disabled]) {
  background-color: #eee;
}

.toolbar select.toolbar-item {
  border: 0;
  display: flex;
  background: none;
  border-radius: 10px;
  padding: 8px;
  vertical-align: middle;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 70px;
  font-size: 14px;
  color: #777;
  text-overflow: ellipsis;
}

.toolbar select.code-language {
  text-transform: capitalize;
  width: 130px;
}

.toolbar .toolbar-item .text {
  display: flex;
  line-height: 20px;
  width: 200px;
  vertical-align: middle;
  font-size: 14px;
  color: #777;
  text-overflow: ellipsis;
  width: 70px;
  overflow: hidden;
  height: 20px;
  text-align: left;
}

.toolbar .toolbar-item .icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  margin-right: 8px;
  line-height: 16px;
  background-size: contain;
}

.toolbar i.chevron-down {
  margin-top: 3px;
  width: 16px;
  height: 16px;
  display: flex;
  user-select: none;
}

.toolbar i.chevron-down.inside {
  width: 16px;
  height: 16px;
  display: flex;
  margin-left: -25px;
  margin-top: 11px;
  margin-right: 10px;
  pointer-events: none;
}

i.chevron-down {
  background-color: transparent;
  background-size: contain;
  display: inline-block;
  height: 8px;
  width: 8px;
  background-image: url(/images/icons/chevron-down.svg);
}

#block-controls button:hover {
  background-color: #efefef;
}

#block-controls button:focus-visible {
  border-color: blue;
}

#block-controls span.block-type {
  background-size: contain;
  display: block;
  width: 18px;
  height: 18px;
  margin: 2px;
}

#block-controls span.block-type.paragraph {
  background-image: url(/images/icons/text-paragraph.svg);
}

#block-controls span.block-type.h1 {
  background-image: url(/images/icons/type-h1.svg);
}

#block-controls span.block-type.h2 {
  background-image: url(/images/icons/type-h2.svg);
}

#block-controls span.block-type.quote {
  background-image: url(/images/icons/chat-square-quote.svg);
}

#block-controls span.block-type.ul {
  background-image: url(/images/icons/list-ul.svg);
}

#block-controls span.block-type.ol {
  background-image: url(/images/icons/list-ol.svg);
}

#block-controls span.block-type.code {
  background-image: url(/images/icons/code.svg);
}

.dropdown {
  z-index: 20;
  display: block;
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
    inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  min-width: 80px;
  min-height: 40px;
  background-color: #fff;
}

.dropdown .item {
  margin: 0 8px 0 8px;
  padding: 8px;
  color: #050505;
  cursor: pointer;
  line-height: 16px;
  font-size: 15px;
  display: flex;
  align-content: center;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 8px;
  border: 0;
  min-width: 188px;
}

.dropdown .item .active {
  display: flex;
  width: 20px;
  height: 20px;
  background-size: contain;
}

.dropdown .item:first-child {
  margin-top: 8px;
}

.dropdown .item:last-child {
  margin-bottom: 8px;
}

.dropdown .item:hover {
  background-color: #eee;
}

.dropdown .item .text {
  display: flex;
  line-height: 20px;
  flex-grow: 1;
  width: 100px;
}

.dropdown .item .icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  margin-right: 12px;
  line-height: 16px;
  background-size: contain;
}

.link-editor {
  position: absolute;
  z-index: 100;
  top: -10000px;
  left: -10000px;
  margin-top: -6px;
  max-width: 300px;
  width: 100%;
  opacity: 0;
  background-color: #fff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  transition: opacity 0.5s;
}

.link-editor .link-input {
  display: block;
  width: calc(100% - 24px);
  box-sizing: border-box;
  margin: 8px 12px;
  padding: 8px 12px;
  border-radius: 15px;
  background-color: #eee;
  font-size: 15px;
  color: rgb(5, 5, 5);
  border: 0;
  outline: 0;
  position: relative;
  font-family: inherit;
}

.link-editor div.link-edit {
  background-image: url(/images/icons/pencil-fill.svg);
  background-size: 16px;
  background-position: center;
  background-repeat: no-repeat;
  width: 35px;
  vertical-align: -0.25em;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
}

.link-editor .link-input a {
  color: rgb(33, 111, 219);
  text-decoration: none;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 30px;
  text-overflow: ellipsis;
}

.link-editor .link-input a:hover {
  text-decoration: underline;
}

.link-editor .button {
  width: 20px;
  height: 20px;
  display: inline-block;
  padding: 6px;
  border-radius: 8px;
  cursor: pointer;
  margin: 0 2px;
}

.link-editor .button.hovered {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-color: #eee;
}

.link-editor .button i,
.actions i {
  background-size: contain;
  display: inline-block;
  height: 20px;
  width: 20px;
  vertical-align: -0.25em;
}

i.undo {
  background-image: url(/images/icons/arrow-counterclockwise.svg);
}

i.redo {
  background-image: url(/images/icons/arrow-clockwise.svg);
}

.icon.paragraph {
  background-image: url(/images/icons/text-paragraph.svg);
}

.icon.large-heading,
.icon.h1 {
  background-image: url(/images/icons/type-h1.svg);
}

.icon.small-heading,
.icon.h2 {
  background-image: url(/images/icons/type-h2.svg);
}

.icon.bullet-list,
.icon.ul {
  background-image: url(/images/icons/list-ul.svg);
}

.icon.numbered-list,
.icon.ol {
  background-image: url(/images/icons/list-ol.svg);
}

.icon.quote {
  background-image: url(/images/icons/chat-square-quote.svg);
}

.icon.code {
  background-image: url(/images/icons/code.svg);
}

i.bold {
  background-image: url(/images/icons/type-bold.svg);
}

i.italic {
  background-image: url(/images/icons/type-italic.svg);
}

i.underline {
  background-image: url(/images/icons/type-underline.svg);
}

i.strikethrough {
  background-image: url(/images/icons/type-strikethrough.svg);
}

i.code {
  background-image: url(/images/icons/code.svg);
}

i.link {
  background-image: url(/images/icons/link.svg);
}

i.left-align {
  background-image: url(/images/icons/text-left.svg);
}

i.center-align {
  background-image: url(/images/icons/text-center.svg);
}

i.right-align {
  background-image: url(/images/icons/text-right.svg);
}

i.justify-align {
  background-image: url(/images/icons/justify.svg);
}

.non-resize {
  resize: none;
}




.image-resizer {
  display: block;
  width: 7px;
  height: 7px;
  position: absolute;
  background-color: rgb(60, 132, 244);
  border: 1px solid #fff;
}

.image-resizer.image-resizer-n {
  top: -6px;
  left: 48%;
  cursor: n-resize;
}

.image-resizer.image-resizer-ne {
  top: -6px;
  right: -6px;
  cursor: ne-resize;
}

.image-resizer.image-resizer-e {
  bottom: 48%;
  right: -6px;
  cursor: e-resize;
}

.image-resizer.image-resizer-se {
  bottom: 34px;
  right: -6px;
  cursor: nwse-resize;
}

.image-resizer.image-resizer-s {
  bottom: -2px;
  left: 48%;
  cursor: s-resize;
}

.image-resizer.image-resizer-sw {
  bottom: 34px;
  left: -6px;
  cursor: sw-resize;
}

.image-resizer.image-resizer-w {
  bottom: 48%;
  left: -6px;
  cursor: w-resize;
}

.image-resizer.image-resizer-nw {
  top: -6px;
  left: -6px;
  cursor: nw-resize;
}

.editor-nested-listitem {
  list-style-type: none;
}

.twitter-tweet {
  margin: auto !important;
}

.editor {
  table {
    font-size: 0.875rem;
  }
  tr {
    border-width: 1px;
    white-space: nowrap;
    max-width: 40%;
  }
  th {
    padding: 4px 8px;
  }
  th:hover {
    border-width: 1px;
    border-color: black;
  }
  td {
    padding: 4px 8px;
  }
  td:hover {
    border-width: 1px;
    border-color: black;
  }
}
.editor-inner {
  table {
    font-size: 0.875rem;
  }
  tr {
    border-width: 1px;
    white-space: nowrap;
    max-width: 40%;
  }
  th {
    padding: 4px 8px;
  }
  td {
    padding: 4px 8px;
  }
}


.fade {
  animation: fade-in-keyframes 4s;
}
@keyframes fade-in-keyframes {
  0% {
      opacity: 1;
      transform: translateY(100%);
    }

    70% {
      opacity: 0.3;
    }

  90% {
      opacity: 0;
      transform: translateY(0%);
    }
  100% {
      opacity: 0;
      transform: translateY(0%);
    }

  }





  .draggable-block-menu {
    border-radius: 4px;
    padding: 2px 1px;
    cursor: grab;
    opacity: 0;
    position: absolute;
    left: -28px;
    top: 0;
    will-change: transform;
  }

  .draggable-block-menu .icon {
    width: 24px;
    height: 24px;
    opacity: 0.3;
    background-image: url(../public/images/icons/draggable-block-menu.svg);
  }

  .draggable-block-menu:active {
    cursor: grabbing;
  }

  .draggable-block-menu:hover {
    background-color:rgba(51, 51, 51, .2);
  }

  .draggable-block-target-line {
    pointer-events: none;
    background: deepskyblue;
    height: 4px;
    position: absolute;
    left: -20px;
    top: 0;
    opacity: 0;
    will-change: transform;
  }


.table-of-contents .heading2 {
  margin-left: 10px;
}

.table-of-contents .heading3 {
  margin-left: 20px;
}

.table-of-contents::-webkit-scrollbar {
  display: none;
}

.selected-heading-wrapper::before {
  content: ' ';
  position: absolute;
  display: inline-block;
  left: -16px;
  top: 4px;
  z-index: 30;
  height: 4px;
  width: 4px;
  border: solid 4px #9e9e9e;
  border-radius: 50%;
}

.normal-heading {
  cursor: pointer;
  line-height: 20px;
}

.table-of-contents {
  color: #65676b;
  position: sticky;
  padding-left: 20px;
  width: 220px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: fit-content;
  overflow-y: auto;
}

.top120 {
  top: 120px;
  max-height: calc(100vh - 160px);
}

.top20 {
  top: 20px;
  max-height: calc(100vh - 60px);
}


.headings {
  list-style: none;
  margin-top: 0;
  padding-left: 6px;
  overflow: scroll;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.headings::-webkit-scrollbar {
  display: none;
}

.headings::before {
  content: ' ';
  position: absolute;
  height: 100%;
  width: 1px;
  left: 25px;
  margin-top: 5px;
  background-color: #eeeeee;
  border-radius: 1px;
  z-index: 0;
}

.normal-heading-wrapper {
  margin-left: 12px;
  position: relative;
}

.normal-dot::before {
  content: ' ';
  position: absolute;
  display: inline-block;
  left: -16px;
  top: 4px;
  z-index: 30;
  height: 4px;
  width: 4px;
  border: solid 4px #eeeeee;
  border-radius: 50%;
}

.max_h_160 {
  max-height: calc(100vh - 160px);
}


.parallax {
  /* The image used */
  background-image: url(/images/sky.jpg);

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.anchor-link {
  display: block;
  padding-top: 70px;
  margin-top: -70px;
}